import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-switch-screen',
  templateUrl: './modal-switch-screen.component.html',
  styleUrls: ['./modal-switch-screen.component.scss']
})
export class ModalSwitchScreenComponent implements OnInit {

  constructor( public modalRef: MDBModalRef, private router: Router ) { }

  ngOnInit(): void {
  }

  proceed() {
    this.router.navigate(["/checkout"]);
  }

}
