import { Component, OnInit, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PagesService } from "src/app/_services/pages.service";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-slice-content-block",
  templateUrl: "./content-block.component.html",
  styleUrls: ["./content-block.component.scss"],
})
export class ContentBlockComponent implements OnInit {
  @Input() public slice: any;
  @Input("vehicleObject") public vehicle: any;
  @Output() emitOpenModal = new EventEmitter<string>();
  @Input() ngClass: any;
  showMobile = false;
  showOutOfWarranty = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.showMobile = true;
    } else {
      this.showMobile = false;
    }
  }
  innerWidth: number;

  constructor(private prismicService: PrismicService, private router: Router, private pagesService: PagesService) {}

  ngOnInit() {
    // console.log('ContentBlockComponent slice: ', this.slice);
    //  console.log('vehicleObject: ', this.vehicle);
    // console.log({slice: this.slice})

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.showMobile = true;
    } else {
      this.showMobile = false;
    }

    if (this.pagesService.isRatingPage(this.router.url)) { // Only show OutOfWarranty on a ratings page
      // Need logic to show OutOfWarranty banner
      let _warranty = sessionStorage.getItem('hasFactoryWarranty');
      if (_warranty === 'false') {
        this.showOutOfWarranty = true;
      }
    }
  }

  getMobileBackgroundImageStyle(sliceContent) {
    let imageStyle;
    if (sliceContent?.mobile_background_image) {
      imageStyle = "url(" + sliceContent.mobile_background_image.url + ")";
    } else {
      imageStyle = this.getDesktopBackgroundImageStyle(sliceContent);
    }

    return imageStyle;
  }
  getDesktopBackgroundImageStyle(sliceContent) {
    let imageStyle;

    if (sliceContent?.background_image) {
      imageStyle = "url(" + sliceContent.background_image.url + ")";
    } else {
      imageStyle = "initial";
    }

    return imageStyle;
  }
  getBackgroundImageStyle(sliceContent) {
    let backgroundImageStyle;

    if (this.innerWidth < 768) {
      backgroundImageStyle = this.getMobileBackgroundImageStyle(sliceContent);
    } else {
      backgroundImageStyle = this.getDesktopBackgroundImageStyle(sliceContent);
    }

    return backgroundImageStyle;
  }

  getRole(slice) {
    if (slice.primary && slice.primary.background_image) {
      return 'img'
    }
    return 'document'
  }

  getAria(slice) {
    if (slice.primary && slice.primary.background_image) {
      return 'promotional images'
    }
    return ''
  }

  getContainerBlockStyles(slice) {
    const style = {
      height: this.innerWidth < 768 ? (slice.primary?.mobile_height ? slice.primary?.mobile_height : "100%") : slice.primary?.height ? slice.primary?.height : "inherit",
      "margin-top": slice.primary?.margin_top + "px",
      "margin-bottom": slice.primary?.margin_bottom + "px",
      position: "relative",
      "background-image": this.getBackgroundImageStyle(slice?.primary),
      "background-repeat": slice.primary?.background_image ? "no-repeat" : "initial",
      "background-position": slice.primary?.background_position ? slice.primary?.background_position : "center",
      bottom: slice.primary?.bottom ? slice.primary?.bottom : "initial",
      "background-size": "cover",
    };

    // console.log({style});

    return style;
  }

  getSectionBlockStyles(slice) {
    const style = {
      // position: slice.primary?.bottom ? "absolute" : "relative",
    };

    // console.log({style});

    return style;
  }

  getContentBlockStyles(feature) {
    const style = {
      width: feature.content_block?.width_percent ? feature.content_block?.width_percent + "%" : "initial",
      color: feature.content_block?.text_color ? feature.content_block?.text_color : "initial",
      // float: feature.content_block?.position ? feature.content_block?.position : "none",
      position: feature.content_block?.bottom ? "absolute" : "relative",
      "background-color": feature.content_block?.background_color ? feature.content_block?.background_color : "initial",
      "background-position": feature.content_block?.background_position ? feature.content_block?.background_position : "center",
      // "text-align": feature.content_block?.text_alignment ? feature.content_block?.text_alignment : "initial",
      "text-align": feature.content_block?.position ? feature.content_block?.position : "center",
      "padding-top": feature.content_block?.padding_top ? feature.content_block?.padding_top + "px" : "initial",
      "padding-bottom": feature.content_block?.padding_bottom ? feature.content_block?.padding_bottom + "px" : "initial",
      "padding-left": feature.content_block?.padding_left ? feature.content_block?.padding_left + "px" : "initial",
      "padding-right": feature.content_block?.padding_right ? feature.content_block?.padding_right + "px" : "initial",
      "margin-top": feature.content_block?.margin_top ? feature.content_block?.margin_top + "px" : "initial",
      height: this.innerWidth < 768 ? (feature.content_block?.mobile_height ? feature.content_block?.mobile_height : "inherit") : feature.content_block?.height ? feature.content_block?.height : "initial",
      bottom: feature.content_block?.bottom ? feature.content_block?.bottom : "initial",
      "background-image": this.getBackgroundImageStyle(feature?.content_block),
      "background-repeat": feature.content_block?.background_image ? "no-repeat" : "initial",
      "background-size": "cover",
      // "background-size": feature.content_block?.background_image ? 'cover': "initial",
    };

    return style;
  }

  openModal(data: string) {
    this.emitOpenModal.emit(data);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
