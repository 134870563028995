// Angular Imports
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, Injector, NgModule, isDevMode } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { AppConfigModule } from "./appconfig/appconfig.module";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// import { MomentModule } from 'ngx-moment';

// UI Libraries
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { MDBSpinningPreloader } from "ng-uikit-pro-standard";

// UI Components
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { VerifyAccountComponent } from "./verify-account/verify-account.component";

// Sandboxes
import { ModalComponent } from "./modal/modal.component";

// Search
import { SearchboxComponent } from "./search/searchbox/searchbox.component";
import { SearchContentComponent } from "./search/search-content/search-content.component";
import { SearchComponent } from "./search/search.component";

// Modal Price Component
import { ModalPriceComponent } from "./modal-price/modal-price.component";
import { CreditCardDirectivesModule } from "angular-cc-library";

// Google
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

// Core Module - include the providers to ensure one instance
// of given service is created across entire application
// (no declarations). Imported in route module only.
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { ServiceWorkerModule, SwRegistrationOptions } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { MyAccountVerifyComponent } from "./my-account/my-account-verify/my-account-verify.component";
import { InlineLoaderComponent } from "./inline-loader/inline-loader.component";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { PreferenceComponent } from "./preference/preference.component";
import { ModalDisclaimerComponent } from "./modal-disclaimer/modal-disclaimer.component";
import { ModalBrowserWarningComponent } from "./modal-browser-warning/modal-browser-warning.component";
import { ModalTimeoutComponent } from "./modal-timeout/modal-timeout.component";
import { MobileNavComponent } from "./navbar/mobile-nav/mobile-nav.component";
import { CartComponent } from "./navbar/cart/cart.component";
import { PhoneModalComponent } from "./navbar/phone-modal/phone-modal.component";
import { DesktopNavComponent } from "./navbar/desktop-nav/desktop-nav.component";
import { DisclaimerComponent } from "./footer/disclaimer/disclaimer.component";
import { QuestionsComponent } from "./footer/questions/questions.component";
import { ContactUsComponent } from "./footer/contact-us/contact-us.component";
import { MyAccountVerifyAccountErrorMessagesComponent } from "./my-account/my-account-verify/my-account-verify-account-error-messages/my-account-verify-account-error-messages.component";
import { MyAccountVerifyEmailComponent } from "./my-account/my-account-verify/my-account-verify-email/my-account-verify-email.component";
import { MyAccountVerifyPhoneComponent } from "./my-account/my-account-verify/my-account-verify-phone/my-account-verify-phone.component";
import { MyAccountVerifyCodeComponent } from "./my-account/my-account-verify/my-account-verify-code/my-account-verify-code.component";
import { MyAccountVerifyNoOrdersComponent } from "./my-account/my-account-verify/my-account-verify-no-orders/my-account-verify-no-orders.component";
import { PrismicService } from "./_services/prismic.service";
import { ModalVinComponent } from "./modal-vin/modal-vin.component";
import { ModalInfoComponent } from "./modal-info/modal-info.component";
import { CookieAcceptanceComponent } from "./cookie-acceptance/cookie-acceptance.component";
import { CheckoutFooterComponent } from "./footer/checkout/checkout.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ModalTechnicalDifficultyComponent } from "./modal-technical-difficulty-error/modal-technical-difficulty-error.component";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular'
import { Router } from "@angular/router";
import myOktaConfig  from './okta-config'
import { ModalOktaErrorComponent } from "./modal-okta-error/modal-okta-error.component";
import { NgwWowModule } from 'ngx-wow';
import { ModalInterstitialComponent } from "./modal-interstitial-popup/modal-Interstitial-popup.component";
import { CurrencyPipe } from "@angular/common";
import { ModalVehicleInspectionComponent } from "./modal-vehicle-inspection/modal-vehicle-inspection.component";
import { NgxFileDropModule } from 'ngx-file-drop';
import { ModalSwitchScreenComponent } from "./modal-switch-screen/modal-switch-screen.component";
import { ModalDeleteDocumentComponent } from "./modal-delete-document/modal-delete-document.component";

const oktaConfig = Object.assign({
  onAuthRequired: (oktaAuth: any, injector: Injector) => {
    const router = injector.get(Router)
    router.navigate(['/verify-account'])
  }
}, myOktaConfig.oidc)

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        ModalComponent,
        PreferenceComponent,
        SearchboxComponent,
        SearchContentComponent,
        SearchComponent,
        ModalPriceComponent,
        VerifyAccountComponent,
        MyAccountVerifyComponent,
        MyAccountVerifyAccountErrorMessagesComponent,
        MyAccountVerifyEmailComponent,
        MyAccountVerifyPhoneComponent,
        MyAccountVerifyCodeComponent,
        MyAccountVerifyNoOrdersComponent,
        ModalBrowserWarningComponent,
        ModalTimeoutComponent,
        MobileNavComponent,
        CartComponent,
        PhoneModalComponent,
        DesktopNavComponent,
        DisclaimerComponent,
        QuestionsComponent,
        ContactUsComponent,
        CheckoutFooterComponent,
        ModalInfoComponent,
        ModalVehicleInspectionComponent,
        ModalSwitchScreenComponent,
        ModalDeleteDocumentComponent,
        ModalInterstitialComponent,
        CookieAcceptanceComponent,
        ModalVinComponent,
        ModalOktaErrorComponent,
        ModalTechnicalDifficultyComponent,
        ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        GooglePlaceModule,
        AppConfigModule,
        MDBBootstrapModulesPro.forRoot(),
        CreditCardDirectivesModule,
        AppRoutingModule,
        NgIdleKeepaliveModule.forRoot(),
        NgwWowModule,
        StoreDevtoolsModule.instrument({
          name: 'DevTools & Debugging in NgRx',
          maxAge: 25,
          logOnly: environment.production, // Restrict extension to log-only mode
        }),
        // MomentModule,
        // OktaAuthModule,
       NgxFileDropModule
    ],
    providers: [
        MDBSpinningPreloader,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: PrismicService.initApp,
            multi: true,
        },
        {
            provide: SwRegistrationOptions,
            useFactory: () => ({ enabled: environment.production }),
        },
        {
            provide: OKTA_CONFIG,
            useValue: oktaConfig
        },
        CurrencyPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
