<div class="customize-container pt-1">
    <div class="row">
        <div class="col-sm-12 offset-md-2 col-md-8 offset-lg-2 col-lg-8 pt-4">
            <div>
                <form role="form" [formGroup]="coverageTermsForm">
                    <div class="row">
                        <div class="col-sm-12 coverage-section">
                            <div class="pt-1 pr-3 choose-terms-section" [ngClass]="{'with-commerical': chosenPlan?.plan.commercialUse || chosenPlan?.plan.liftKit || chosenPlan?.plan.snowPlow || chosenPlan?.plan.greyMarket}">
                                <div class="p-2">
                                    <div class="w-100">
                                        <div *ngIf="showNCESITerm">
                                            <h2 tabindex="0" id="termlength" class="active ncesi-term-length"
                                                aria-label="{{ 'RATES_PAGE.CHOOSE_ENDS' | translate }}">{{ 'RATES_PAGE.CHOOSE_ENDS' | translate }}**
                                            </h2>
                                            <div class="term-length-sub">{{ 'RATES_PAGE.COVERAGE_EXP' | translate }}</div>
                                            <div class="md-form terms-select">
                                                <select role="listbox" class="form-control" aria-labelledby="termlength"
                                                    formControlName="terms" id="terms" (change)="updatePricing()">
                                                    <option *ngFor="let term of chosenPlan?.planTerms"
                                                        value="{{term.value}}">
                                                        {{convertTermLabel(term.label)}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="md-form" *ngIf="!showNCESITerm">
                                            <label tabindex="0" id="termlength" class="active"
                                                aria-label="{{ 'RATES_PAGE.CHOOSE_TERM' | translate }}">{{ 'RATES_PAGE.CHOOSE_TERM' | translate }}**
                                            </label>
                                            <select role="listbox" class="form-control" aria-labelledby="termlength"
                                                formControlName="terms" id="terms" (change)="updatePricing()">
                                                <option *ngFor="let term of chosenPlan?.planTerms"
                                                    value="{{term.value}}">
                                                    {{term.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="text-center mb-5 desktop" *ngIf="showNCESITerm">
                                        <span class="estimated-coverage-text">{{ 'RATES_PAGE.ESTIMATED_COVERAGE' | translate }}: </span>
                                        <span class="estimated-coverage-terms"> {{expireDate | date: 'longDate' : '+000' : preferredLanguage}} {{ 'RATES_PAGE.OR' | translate }} {{expireMileage}} {{mileage}}</span>
                                    </div>
                                    <div class="text-center mb-5 mobile" *ngIf="showNCESITerm">
                                        <div class="estimated-coverage-text">{{ 'RATES_PAGE.ESTIMATED_COVERAGE' | translate }}: </div>
                                        <div class="estimated-coverage-terms"> {{expireDate | date: 'longDate' : '+000' : preferredLanguage}} {{ 'RATES_PAGE.OR' | translate }} {{expireMileage}} {{mileage}}</div>
                                    </div>

                                </div>
                            </div>

                            <!-- <div style="border: 4px solid  red" class="commercial-use-section" *ngIf="chosenPlan?.plan.commercialUse || chosenPlan?.plan.liftKit || chosenPlan?.plan.snowPlow || chosenPlan?.plan.greyMarket">
                                <div *ngIf="chosenPlan?.plan.commercialUse">
                                    <div class="switch success-switch pl-2 pr-2 pb-3">
                                        <div class="row">
                                            <label style="overflow: visible;" tabindex="0" id="lightduty">
                                                Light Duty Commercial Use Coverage
                                                <a class="click-text" role="link" tabindex="0" (click)="commercial_use.toggle()">{{ 'RATES_PAGE.WHATS_THIS' | translate }}</a>
                                            </label>
                                        </div>
                                        <div class="row">
                                            <label>
                                                {{ 'RATES_PAGE.NO' | translate }}
                                                <input aria-labelledby="lightduty" id="commercialUse"
                                                    formControlName="commercialUse"
                                                    [checked]="coverageTermsForm.controls.commercialUse.value"
                                                    type="checkbox"
                                                    (change)="handleCommercialUse(coverageTermsForm.controls.commercialUse.value)">
                                                <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                                            </label>
                                        </div>
                                        <div class="row whats_this_panel">
                                            <div mdbCollapse #commercial_use>
                                                <div>
                                                    {{commercial_use_tooltip}}
                                                </div>
                                                <a class="click-text" (click)="commercial_use.toggle()">{{ 'RATES_PAGE.CLOSE' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chosenPlan?.plan.liftKit">
                                    <div class="switch success-switch pl-2 pt-2 pr-2 pb-3">
                                        <div class="row">
                                            <label style="overflow: visible;" tabindex="0" id="liftkit">
                                                Lift Kit/Tire Modification Coverage
                                                <a class="click-text" role="link" tabindex="0" (click)="lift_kit.toggle()">{{ 'RATES_PAGE.WHATS_THIS' | translate }}</a>
                                            </label>
                                        </div>
                                        <div class="row">
                                            <label>
                                                {{ 'RATES_PAGE.NO' | translate }}
                                                <input aria-labelledby="liftkit" id="liftKit"
                                                    formControlName="liftKit"
                                                    [checked]="coverageTermsForm.controls.liftKit.value" type="checkbox"
                                                    (change)="handleLiftKit(coverageTermsForm.controls.liftKit.value)">
                                                <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                                            </label>
                                        </div>
                                        <div class="row whats_this_panel">
                                            <div mdbCollapse #lift_kit>
                                                <div>
                                                    {{lift_kit_tooltip}}
                                                </div>
                                                <a class="click-text" role="link" tabindex="0" (click)="lift_kit.toggle()">{{ 'RATES_PAGE.CLOSE' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chosenPlan?.plan.snowPlow">
                                    <div class="switch success-switch pl-2 pt-2 pr-2 pb-3">
                                        <div class="row">
                                            <label style="overflow: visible;" tabindex="0" id="snowplow">
                                                Snow Plow Use
                                                <a class="click-text" role="link" tabindex="0" (click)="snow_plow.toggle()">{{ 'RATES_PAGE.WHATS_THIS' | translate }}</a>
                                            </label>
                                        </div>
                                        <div class="row">
                                            <label>
                                                {{ 'RATES_PAGE.NO' | translate }}
                                                <input aria-labelledby="snowplow" id="snowPlow"
                                                    formControlName="snowPlow"
                                                    [checked]="coverageTermsForm.controls.snowPlow.value"
                                                    type="checkbox"
                                                    (change)="handleSnowPlow(coverageTermsForm.controls.snowPlow.value)">
                                                <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                                            </label>
                                        </div>
                                        <div class="row whats_this_panel">
                                            <div mdbCollapse #snow_plow>
                                                <div>
                                                    {{snow_plow_tooltip}}
                                                </div>
                                                <a class="click-text" role="link" tabindex="0" (click)="snow_plow.toggle()">{{ 'RATES_PAGE.CLOSE' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chosenPlan?.plan.greyMarket">
                                    <div class="switch success-switch pl-2 pt-2 pr-2 pb-3">
                                        <div class="row">
                                            <label style="overflow: visible;" tabindex="0" id="greymarket">
                                                Grey Market, Required for Grey Market
                                                <a class="click-text" role="link" tabindex="0" (click)="grey_market.toggle()">{{ 'RATES_PAGE.WHATS_THIS' | translate }}</a>
                                            </label>
                                        </div>
                                        <div class="row">
                                            <label>
                                                {{ 'RATES_PAGE.NO' | translate }}
                                                <input aria-labelledby="greymarket" id="greyMarket"
                                                    formControlName="greyMarket"
                                                    [checked]="coverageTermsForm.controls.greyMarket.value"
                                                    type="checkbox"
                                                    (change)="handleGreyMarket(coverageTermsForm.controls.greyMarket.value)">
                                                <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                                            </label>
                                        </div>
                                        <div class="row whats_this_panel">
                                            <div mdbCollapse #grey_market>
                                                <div>
                                                    {{grey_market_tooltip}}
                                                </div>
                                                <a class="click-text" role="link" tabindex="0" (click)="grey_market.toggle()">{{ 'RATES_PAGE.CLOSE' | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="term-statement" tabindex="0">
                        **{{term_length_tooltip}}
                    </div>
                    <div class="col-12 text-center mb-3 mt-3 desktop">
                        <button mdbBtn class="btn primary-btn waves-light" mdbWavesEffect (click)="addToCart()"
                            id="add-to-cart-button">
                            <span>
                                {{ 'RATES_PAGE.VIEW_PAYMENT_OPTIONS' | translate }}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
