
import { createReducer, on } from '@ngrx/store';
import { rehydrateDealer, validateDealer, validateDealerError, validateDealerSuccess } from '../_actions/dealer-actions';
import { DealerValidationState } from '../_models/dealer';


export const initialState = null;


const _dealerValidationReducer = createReducer(initialState,
  on(validateDealer, (state, payload) => {
    console.log('reducer validateDealer', state, payload)
    return state;

  }),
  on(validateDealerSuccess, (state, payload) => {
    console.log('reducer validateDealerSuccess', state, payload)
    sessionStorage.setItem('dealer', JSON.stringify(payload))
    return payload;

  }),
  on(validateDealerError, (state, payload) => {
    console.log('reducer validateDealerError', state, payload)
    return {success: false};

  }),
  on(rehydrateDealer, (state, payload) => {
    console.log('reducer validateDealerSuccess', state, payload)
    sessionStorage.setItem('dealer', JSON.stringify(payload))
    return payload;

  }),
);


export function dealerValidationReducer(state, action) {
  return _dealerValidationReducer(state, action);
}
