import { Injectable } from "@angular/core";
import brandInfo from "../../environments/brand.json";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {

  constructor() {}

  loadScriptTag(): void {

    let language = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language

    const googleMaps = document.createElement('script');
    // googleMaps.setAttribute('async', 'true');
    googleMaps.setAttribute('type','text/javascript');
    googleMaps.setAttribute('src','https://maps.googleapis.com/maps/api/js?key=AIzaSyAd05MP2ZzuZjJE4Pw1iO2pJLc16nATFIA&libraries=places&language=' + language.split('-')[0])
    document.documentElement.firstChild.appendChild(googleMaps);
    
  }
}
