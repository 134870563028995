export const environment = {
  production: false,
  baseUrl: 'https://dtc-gateway-srvc-dev.herokuapp.com/dtc-gateway-srvc/',
  apiUrl: 'https://mango-node-dev.herokuapp.com',
  logrocket: 'npobc9/d2c-dev',
  // apiUrl: 'http://localhost:8080',
  fakeApiUrl: 'https://mango-node-dev.herokuapp.com/dtc-gateway-srvc/',
  oktaConfig: {
    clientId: "0oa5sgfz8nMUXVl361d7",
    issuer: "https://safeguard-dev-d2c.oktapreview.com/oauth2/default",
    redirectUri: "http://localhost:4200/login/callback",
    scopes: [
      "openid",
      "profile"
    ]
  },
  paypalDetails: {
    clientId: 'sb',
    currency: 'USD'
  }
};
