<div class="row" *ngIf="screen == 'LOGIN'">
    <div class="col-sm-12 col-md-6 offset-md-3 mt-4">
        <div><strong>{{component.primary.title[0].text}}</strong></div>
        <div>{{component.primary.subtitle[0].text}}</div>

        <div class="p-0" *ngIf="badDealerLogin">
            <div class="mt-3">
                <div class="alert alert-warning verify-alert w-100 pb-0 text-center" role="alert">Could not Login.
                    Please check your credentials.</div>
            </div>
        </div>

        <form role="form" class="dealership_login" [formGroup]="loginForm">
            <div class="md-form mt-5">
                <label id="dealerlabel" for="dealershipId">Dealership ID</label>
                <input aria-labelledby="dealerlabel" mdbInput mdbValidate type="text" id="dealershipId"
                    formControlName="dealershipId" class="form-control" maxlength="17" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.dealershipId.invalid && (loginForm.controls.dealershipId.dirty || loginForm.controls.dealershipId.touched)">
                    *Dealership ID required.
                </mdb-error>
            </div>
            <div class="md-form mt-5">
                <label id="employeelabel" for="employeeId">Employee ID</label>
                <input aria-labelledby="employeelabel" mdbInput mdbValidate type="text" id="employeeId"
                    formControlName="employeeId" class="form-control" minlength="3" maxlength="3" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.employeeId.invalid && (loginForm.controls.employeeId.dirty || loginForm.controls.employeeId.touched)">
                    *Employee ID required.
                </mdb-error>
            </div>
            <div class="mt-5">
                <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn flat waves-light" mdbWavesEffect
                    type="button" (click)="validateDealer('CAPTURE')">
                    Scan VIN
                </button>
            </div>
            <div class="text-center w-100 mt-2"><a (click)="validateDealer('CUSTOMER'); readonly = false;"
                    class="blue-link uppercase">Manual VIN Input</a>
            </div>
        </form>
    </div>
</div>

<div class="row" *ngIf="screen == 'CAPTURE'">
    <div class="col-12">
        <div class="text-center w-100 mt-2">
            <div class="preloader-wrapper active indicator mt-5 mb-5" *ngIf="showCamera == false">
                <div class="spinner-layer spinner-client-color">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
            <div id="scandit-barcode-picker" [class]="{'show': showCamera == true}"></div>
            <div [hidden]="!barcodeValues || barcodeValues.length == 0" class="mt-2">
                <strong>Click to Use:</strong><br>
                <div class="mt-2 mb-2" *ngFor="let barcode of barcodeValues"><button class="btn primary-btn"
                        (click)="useVIN(barcode)">{{ barcode }}</button></div>
            </div>
            <div class="text-center w-100 mt-2"><a (click)="validateDealer('CUSTOMER'); readonly = false;" class="blue-link uppercase">Manual VIN Input</a></div>
            <div class="mt-2 mb-2"><a (click)="loadView('LOGIN')" class="blue-link uppercase">Back to Login</a></div>

        </div>
    </div>
</div>

<div class="row" *ngIf="screen == 'CUSTOMER'">
    <div class="col-sm-12 col-md-4 offset-md-4 mt-4 mb-4">
        <div><strong>{{component.primary.vin_title[0].text}}</strong></div>
        <div>{{component.primary.vin_subtitle[0].text}}</div>
    </div>
    <div class="manual_container col-12">
        <form role="form" class="vehicle_login row" [formGroup]="customerForm">
            <div class="vehicle pr-3 pl-3 mt-3">
                <div class="vehicle_login row">
                    <div class="col-12 pb-5 pr-1 pl-1">
                        <div class="md-form">
                            <label id="vinlabel" for="vin">VIN</label>
                            <input aria-labelledby="vinlabel" mdbInput mdbValidate type="text" id="vin"
                                oninput="this.value = this.value.toUpperCase()" [readonly]="readonly"
                                formControlName="vin" class="form-control" maxlength="17"
                                [class]="{'grey-input': readonly}" autocomplete="off"
                                (change)="lookupVin(customerForm.controls.vin.value)" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.vin.invalid && (customerForm.controls.vin.dirty || customerForm.controls.vin.touched)">
                                *VIN required.
                            </mdb-error>
                            <mdb-error role="alert" *ngIf="customerForm.controls.vin.valid && invalidVin">
                                *Invalid VIN.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="yearlabel" for="year">Year</label>
                            <input aria-labelledby="yearlabel" mdbInput mdbValidate type="number" id="year" readonly
                                formControlName="year" class="form-control grey-input" max="9999" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.year.invalid && (customerForm.controls.year.dirty || customerForm.controls.year.touched)">
                                *Year required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="makeLabel" for="make">Make</label>
                            <input aria-labelledby="makeLabel" mdbInput mdbValidate type="text" id="make" readonly
                                formControlName="make" class="form-control grey-input" maxlength="20" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.make.invalid && (customerForm.controls.make.dirty || customerForm.controls.make.touched)">
                                *Make required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="modelLabel" for="model">Model</label>
                            <input aria-labelledby="modelLabel" mdbInput mdbValidate type="text" id="model" readonly
                                formControlName="model" class="form-control grey-input" maxlength="20" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.model.invalid && (customerForm.controls.model.dirty || customerForm.controls.model.touched)">
                                *Model required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-12 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="odometerlabel" for="odometer">Odometer</label>
                            <input aria-labelledby="odometerlabel" mdbInput mdbValidate type="text" id="odometer"
                                formControlName="odometer" class="form-control" (keypress)="onlyNumberKey($event)"
                                (focus)="removeCommas()" (change)="removeCommas()" (blur)="addCommas()" max="100000" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.odometer.invalid && (customerForm.controls.odometer.dirty || customerForm.controls.odometer.touched)">
                                *Odometer required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-12 pr-1 pl-1 pb-5">
                        <div class="md-form text-left no-shadow">
                            <label id="warrantylabel" for="hasWarranty">Is vehicle under New Car Warranty?*</label>
                            <!-- <input type="hidden" formControlName="hasWarranty"/> -->
                            <button (click)="setWarranty(true)"
                                [class]="{active: customerForm.controls.hasWarranty.value == true}"
                                class="btn uppercase warranty_btn">Yes</button>&nbsp;
                            <button (click)="setWarranty(false)"
                                [class]="{active: customerForm.controls.hasWarranty.value == false}"
                                class="btn uppercase warranty_btn">No</button>
                            <div class="w-100 error-message mt-2">*Vehicle inspection document will be required at
                                checkout for vehicles out of warranty.</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="customer mt-3 pr-3 pl-3">
                <div class="customer_login row">
                    <div class="col-6 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="firstNamelabel" for="firstName">First Name</label>
                            <input aria-labelledby="firstNamelabel" mdbInput mdbValidate type="text" id="firstName"
                                formControlName="firstName" class="form-control" maxlength="20" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.firstName.invalid && (customerForm.controls.firstName.dirty || customerForm.controls.firstName.touched)">
                                *First Name required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-6 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="lastNamelabel" for="lastName">Last Name</label>
                            <input aria-labelledby="lastNamelabel" mdbInput mdbValidate type="text" id="lastName"
                                formControlName="lastName" class="form-control" maxlength="20" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.lastName.invalid && (customerForm.controls.lastName.dirty || customerForm.controls.lastName.touched)">
                                *Last Name required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-12 pb-5 pr-1 pl-1">
                        <div class="md-form">
                            <label id="addresslabel" for="address">Address</label>
                            <input aria-labelledby="addresslabel" mdbInput mdbValidate type="text" id="address"
                                ngx-google-places-autocomplete #placesRef="ngx-places"
                                (onAddressChange)="handleBillingAddressChange($event)" formControlName="address"
                                class="form-control" maxlength="30" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.address.invalid && (customerForm.controls.address.dirty || customerForm.controls.address.touched)">
                                *Address required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-2 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="address2label" for="address2">Apt./Ste.</label>
                            <input aria-labelledby="address2label" mdbInput mdbValidate type="text" id="address2"
                                formControlName="address2" class="form-control" maxlength="20" />
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="citylabel" for="city">City</label>
                            <input aria-labelledby="citylabel" mdbInput mdbValidate type="text" id="city"
                                formControlName="city" class="form-control" maxlength="30" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.city.invalid && (customerForm.controls.city.dirty || customerForm.controls.city.touched)">
                                *City required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-2 pr-1 pl-1">
                        <div class="md-form">
                            <label id="stateLabel" for="vin">State</label>
                            <select role="listbox" aria-labelledby="stateLabel" id="state" class="form-control"
                                formControlName="state" placeholder="State ... *">
                                <option *ngFor="let state of states" [value]="state.value">{{ state.label }}</option>
                            </select>
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.state.invalid && (customerForm.controls.state.dirty || customerForm.controls.state.touched) || customerForm.controls.state.value == ''">
                                *Required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="ziplabel" for="zip">Zip</label>
                            <input aria-labelledby="ziplabel" mdbInput mdbValidate type="text" id="zip"
                                formControlName="zip" class="form-control" maxlength="5" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.zip.invalid && (customerForm.controls.zip.dirty || customerForm.controls.zip.touched)">
                                *Zip required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-6 pr-1 pl-1">
                        <div class="md-form">
                            <label id="emaillabel" for="email">Email</label>
                            <input aria-labelledby="emaillabel" mdbInput mdbValidate type="text" id="email"
                                formControlName="email" class="form-control" maxlength="30" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.email.invalid && (customerForm.controls.email.dirty || customerForm.controls.email.touched)">
                                *Email required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-6 pr-1 pl-1">
                        <div class="md-form">
                            <label id="phonelabel" for="phone">Phone</label>
                            <input aria-labelledby="phonelabel" mdbInput mdbValidate type="text" id="phone"
                                formControlName="phone" class="form-control" maxlength="10" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.phone.invalid && (customerForm.controls.phone.dirty || customerForm.controls.phone.touched)">
                                *Phone required.
                            </mdb-error>
                        </div>
                    </div>
                </div>
            </div> -->
        </form>
    </div>
    <div class="col-12">
        <div class="mt-5">
            <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn flat waves-light" (click)="getRates()"
                mdbWavesEffect type="submit">
                View Rates
            </button>
        </div>
        <div class="text-center w-100 mt-2">
            <a (click)="loadView('CAPTURE')" class="blue-link uppercase">Change Vehicle</a>
        </div>
    </div>
</div>