export class RatesRequest {
    brandName: string;
    vin: string;
    saleDate: string;
    odometer: string;
    inServiceDate: string;
    financeType: string;
    isAfterSale: string;
    vehiclePurchaseDate: string;
    inserviceDate?:string;
    mfrwarrantyexpDate?:string;
    exteriorColor?: string;
    deliveryDate?:string;
    vehicleCondition: string;
    customerState: string;
    financeAmount: string;
    vehicleCost: string;
    vehicleMSRP: string;
    lienholderName: string;
    make: string;
    model: string;
    year: string;
    vehicleSfId: string;
    getRatesPage?:string;
    product: string;
    dealer?: string;
}

export class RatesResponse {
    status: string;
    data: RateData;
    errorMessage: string;
}

export class RateData {
    productDetailsList: ProductDetailsList[];
    vehicleYear: string;
    vehicleMake: string;
    vehicleModel: string;
    vehicleTrim: string;
    inServiceDate?: string;
    paymentOptions: boolean;
    paymentTerms: string[];
    dealerId?: string;
}

export class ProductDetailsList {
    productName: string;
    productNumber: string;
    surcharge: Surcharge;
    productCode: string;
    statusCode: number;
    message: string;
    productFormNumber: string;
    productExternalId: string;
    productSfId: string;
    productPlanList: ProductPlanList[];
}

export class Surcharge {
    surchargeOption: SurchargeOption[];
}

export class SurchargeOption {
    key: string;
    type: string;
    label: SurchargeOptionLabels;
    surchargeAmount: number;
    mandatory: boolean;
}

export class SurchargeOptionLabels {
    enLabel: string;
    frLabel: string;
}

export class ProductPlanList {
    planName: string;
    planNumber: string;
    referenceNumber1: string;
    planCode: string;
    planTermSKUList: PlanTermSKUList[];
    deductible: string;


}

export class PlanTermSKUList {
    planTermSKUName: string;
    planTermSKURate: string;
    planTermSKUNumber: string;
    productSkuSfId: string;
    productPlanSkuId: string;
    contractDealerCost: string;
    paymentTerms: string[];
}

export class SliceRateMapping {
    referenceID: string;
    planName: string;
    lowestTerm: number;
    lowestTermTotalPrice: number;
    lowestTermMonthlyPrice: number;
    shortDescription: SliceRateShortDescriptionMapping[];
    coverageDisclaimer: string;
    components: SliceRateMappingComponent[];
    downPayment: number;
}

export class SliceRateShortDescriptionMapping {
    spans: string[];
    text: string;
    type: string;
}

export class SliceRateMappingArray {
    data: SliceRateMapping[];
}

export class SliceRateMappingComponent {
    title: string;
    overview: string;
    icon: SliceRateMappingComponentIcon;
    description: SliceRateMappingComponentDescription[];
}

export class SliceRateMappingComponentIcon {
    url: string;
    width: string;
    height: string;
}

export class SliceRateMappingComponentDescription {
    text: string;
}
