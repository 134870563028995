import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InlineLoaderComponent } from '../inline-loader/inline-loader.component';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { AppTooltipComponent } from '../tooltip/tooltip.component';
import { VerifyVehicleComponent } from './verify-vehicle/verify-vehicle.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer'; //
import { ErrorMessageComponent } from './error-message/error-message.component';
import { AddOnsComponent } from './surcharge-commercial/add-ons.component';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    InlineLoaderComponent,
    CloseButtonComponent,
    AppTooltipComponent,
    VerifyVehicleComponent,
    // Pipes
    SafeHtmlPipe,
    ErrorMessageComponent,
    AddOnsComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    MDBBootstrapModulesPro,
    TranslateModule.forChild(),
    PdfViewerModule,
    MatDialogModule
   ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    InlineLoaderComponent,
    CloseButtonComponent,
    AppTooltipComponent,
    VerifyVehicleComponent,
    // Pipes
    SafeHtmlPipe,
    TranslateModule,
    ErrorMessageComponent,
    AddOnsComponent
  ]
})
export class SharedModule { }
