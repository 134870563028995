import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map, sample } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { MakeResponse } from "../_models/make";
import { Model } from "../_models/model";
import { Trim } from "../_models/trim";
import { Vehicle, VerifyCustVehReq, VerifyVehCustResponse } from "../_models/vehicle";
import brandInfo from "../../environments/brand.json";

import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";
import { environment } from '../../environments/environment'


declare var gtag;

@Injectable({
  providedIn: "root",
})
export class DataLookupService {
  /**
   * Data Lookup Servie Methods
   *  1. Get Contract PDF
   *  2. Get Makes
   *  3. Get Models
   *  4. Get Trims
   *  5. Get Odometer Ranges (may not need) [not added yet]
   *  6. Get Model-Trim Combinations (may not need) [not added yet]
   *  7. Get Price (may not need) [not added yet]
   *  8. Get Vehicle
   *  9. Add Vehicle
   *  10. Update Vehicle
   *  11. Delete Vehicle
   */

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private loggingService: LoggingService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  generatePDF(object): Observable<any> {
    console.log("pdfObject", object);
    return this.httpClient.get<any>(this.gatewayService.baseUrl + "dtc-data-srvc/contract/orderitem/pdf?orderSummaryId=" + object.orderSummaryId + "&accountSfid=" + object.accountSfId).pipe(retry(1), catchError(this.loggingService.handleError));
  }

  check_vin(vin): Observable<any> {
    return this.httpClient.get<any>(this.gatewayService.baseUrl + "dtc-data-srvc/vehicle/vin/" + vin.toUpperCase()).pipe(retry(1), catchError(this.loggingService.handleError));
  }

  check_vin_customer(object): Observable<VerifyVehCustResponse> {
    return this.httpClient.get<VerifyVehCustResponse>(this.gatewayService.baseUrl + "dtc-data-srvc/vehicle/verifyCustomerAndVehicle?brandName=" + brandInfo.brand + "&emailId=" + encodeURIComponent(object.email) + "&vin=" + object.vin.toUpperCase()).pipe(retry(1), catchError(this.loggingService.handleError));
  }

  setDateAndTime(orderSummaryID): Observable<any> {
    console.log('orderSummaryIDorderSummaryIDorderSummaryIDorderSummaryID', orderSummaryID)
      return this.httpClient.post(`${environment.apiUrl}/v1/api/order/contractaccessed`, {
        orderSummaryId: orderSummaryID.orderSummaryId,
      })
      .pipe(
          retry(1),
          catchError(this.loggingService.handleError)
      )
    }


  postPaymentPlanAgreementPdf(paymentPlanAgreementPayload): Observable<any> {
    const payload = {...paymentPlanAgreementPayload, type: paymentPlanAgreementPayload.docType};
    delete payload.docType;
    console.log('payload is', payload)
    return this.httpClient.post(`${environment.apiUrl}/v1/api/ppa/generate`, payload)
    .pipe(
        retry(1),
        catchError(this.loggingService.handleError)
    )
  }

  // call to the service agreement go get a pdf back
  postServiceAgreementPdf(serviceAgreementPaylod): Observable<any> {
    console.log('payload is', serviceAgreementPaylod)
    return this.httpClient.post(`${environment.apiUrl}/v1/api/contract/generate`, serviceAgreementPaylod)
    .pipe(
        retry(1),
        catchError(this.loggingService.handleError)
    )
  }


  getPaymentPlanAgreementPdf(payload): Observable<any> {
    console.log('getpatymentPLan', payload)
    return this.httpClient.get(`${environment.apiUrl}/v1/api/ppa/generate?orderNumber=` + payload.orderNumber + "&accountSfId=" + payload.accountSfId )
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }


  // Autocheck API after Billing Step in UI
  verifyAutoCheckVinNumber(vinNumber, mileage): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/v1/api/autocheck/` + vinNumber+`?odometer=${mileage}`)
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
    )

  }

}




