import { Injectable } from "@angular/core";
import * as Prismic from "prismic-javascript";
import { PrismicConfig } from "src/prismic-config";
import { RichText, PrismicDOM } from "prismic-dom";
import Cookies from "js-cookie";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

const Elements = RichText.Elements;

@Injectable({
  providedIn: "root",
})
export class PrismicService {
  constructor(private httpClient: HttpClient) {}

  static async getEndpointAndToken(token) {
    let splitToken = token.split("/");

    let baseUrl = splitToken[2];

    const env = environment.production ? "prod" : "dev";

    let prismicEnv;

    let response = await fetch(`${environment.apiUrl}/v1/api/content/envs/all`);
    let prismicEnvs = await response.json();

    console.log("prismicEnvs: ", prismicEnvs);

    try {
      prismicEnv = prismicEnvs.filter((env) => env.baseUrl === baseUrl)[0];
      // console.log("Storing prismicEnv: ", prismicEnv);

      sessionStorage.setItem("prismicEnv", JSON.stringify(prismicEnv));

    } catch (error) {
      console.log("Error get prismic envs: ", error);
    }

    let newHeader = {};
    newHeader[baseUrl] = { preview: token };

    localStorage.setItem("Prismic-ref", JSON.stringify(newHeader));
    localStorage.setItem("Prismic-baseUrl", prismicEnv.baseUrl);
    sessionStorage.setItem("prismicEnv", JSON.stringify(prismicEnv));

    console.log("%c localStorage set Prismic-ref: ", "color: #1ECBE1", JSON.stringify(newHeader));
    console.log("%c localStorage set Prismic-baseUrl: ", "color: #1ECBE1", prismicEnv.baseUrl);

    return prismicEnv;
  }

  getEnvironment(env) {
    // Get Environment from Session Storage
    let _prismicEnv = sessionStorage.getItem("prismicEnv");

    if (_prismicEnv) {
      return JSON.parse(_prismicEnv);
    }

    console.log("prismicEnv fetching from api...");

    // Set the environment

    this.fetchEnvironment(env).then((prismicEnv) => {
      console.log("prismicEnv...", prismicEnv);
      sessionStorage.setItem("prismicEnv", JSON.stringify(prismicEnv));

      return prismicEnv;
    });


  }

  async fetchEnvironment(env) {
    let response = await fetch(`${environment.apiUrl}/v1/api/content/envs?env=${env}`);
    let prismicEnv = response.json();
    return prismicEnv;
  }

  // Get the API object which we will use for all our queries
  async getApi(token): Promise<void | any> {
    // console.log("getApi token: ", token);
    let prismicEnv = await PrismicService.getEndpointAndToken(token);

    return Prismic.api(prismicEnv.apiEndpoint, { accessToken: prismicEnv.accessToken })
      .then((api) => {
        console.log("Got Prismic API:", api);
        return api;
      })
      .catch((e) => console.log(`Error during connection to your Prismic API: ${e}`));
  }

  static initApp() {
    return () => {
      //  console.log("window.location.href", window.location.href);

      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);
      //  console.log(urlParams.get("token"));

      if (urlParams.has("token")) {
        return PrismicService.getEndpointAndToken(urlParams.get("token"));
      } else {
        return PrismicService.storePrismicRef();
      }

      // return PrismicService.storePrismicRef();
    };
  }

  isPreviewMode() {
    const previewRef = Cookies.get("io.prismic.preview");

    if (previewRef) {
      const parsedCookie = JSON.parse(previewRef);
      const isPreview = !!(parsedCookie["sgidigital-dev.prismic.io"] || parsedCookie["sgidigital.prismic.io"]);
      return isPreview;
    } else {
      return false;
    }
  }

  static storePrismicRef = async () => {
    console.log('%c **** prismicRef **** ', 'color: #188977')


    // Get the Prismic Env from API
    const env = environment.production ? "prod" : "dev";
    let response = await fetch(`${environment.apiUrl}/v1/api/content/envs?env=${env}`);
    let prismicEnv = await response.json();

    try {
      // console.log("Storing prismicEnv: ", prismicEnv);
      sessionStorage.setItem("prismicEnv", JSON.stringify(prismicEnv));
    } catch (error) {
      console.log("Error get prismic envs: ", error);
    }



    let apiData = await Prismic.api(prismicEnv.apiEndpoint, { accessToken: prismicEnv.accessToken });
    const masterRef = apiData.refs.find((ref) => ref.isMasterRef === true);
    const prismicRef = masterRef.ref;

    const previewRef = Cookies.get("io.prismic.preview");

    if (previewRef) {
      const parsedCookie = JSON.parse(previewRef);
      const prismic_env = parsedCookie["sgidigital-dev.prismic.io"] || parsedCookie["sgidigital.prismic.io"] ? "Preview Mode" : "Master Mode";
      const prismicRef = parsedCookie["sgidigital-dev.prismic.io"] || parsedCookie["sgidigital.prismic.io"] ? previewRef : masterRef.ref;
      localStorage.setItem("Prismic-ref", prismicRef);

      // console.log('Prismic cookieUrl:',PrismicConfig.cookieUrl) // the undefined value
      // console.log('Prismic PreviewRef:',parsedCookie) // the undefined value
      // console.log('Entering into:',prismic_env) // the undefined value
      // console.log("Prismic-ref: ", prismicRef);
      // console.log('%c ***** ', 'color: #188977')
    } else {
      localStorage.setItem("Prismic-ref", prismicRef);
      localStorage.setItem("Prismic-baseUrl", prismicEnv.baseurl);
      // console.log("Prismic-ref: ", prismicRef);
      // console.log('Prismic-baseUrl', PrismicConfig.baseurl);
      // console.log('%c ***** ', 'color: #188977')
    }

    return prismicRef;
  };

  // Use the Link Resolver to return the correct url for your previews
  getPreviewUrl(token: string): Promise<string> {
    return this.getApi(token).then((api) => {
      console.log("getApi api: ", api);
      return api
        .previewSession(token, PrismicConfig.linkResolver, "/")
        .then((url) => {
          console.log("previewSession Found url: ", url);
          return url;
        })
        .catch((error) => {
          console.log("previewSession Caught Error from getPreviewUrl", error);
          return "/";
        });
    });
  }

  getHtml(content, vehicleObject = null) {
    // Custom string interpolation
    if (!content) {
      return;
    }
    let htmlContent = RichText.asHtml(content, PrismicConfig.linkResolver, this.htmlSerializer);

    if (vehicleObject) {
      htmlContent = htmlContent.replace("{Brand}", vehicleObject.make.name);
      htmlContent = htmlContent.replace("{Year}", vehicleObject.year);
      htmlContent = htmlContent.replace("{Model}", vehicleObject.model.name);
      htmlContent = htmlContent.replace("{0,000}", "5,000");
      htmlContent = htmlContent.replace("{Trim}", vehicleObject.trim);
    }

    return htmlContent;
  }

  htmlSerializer(type, element, content, children) {
    switch (type) {
      case Elements.heading1:
        return `<h1>${children.join("")}</h1>`;
      case Elements.heading2:
        return `<h2>${children.join("")}</h2>`;
      case Elements.heading3:
        return `<h3>${children.join("")}</h3>`;
      case Elements.heading4:
        return `<h4>${children.join("")}</h4>`;
      case Elements.heading5:
        return `<h5>${children.join("")}</h5>`;
      case Elements.heading6:
        return `<h6>${children.join("")}</h6>`;
      case Elements.paragraph:
        return `<p>${children.join("")}</p>`;
      case Elements.preformatted:
        return `<div>${children.join("")}</div>`; // Change Preformatted Prismic content to DIV structure
      case Elements.strong:
        return `<strong>${children.join("")}</strong>`;
      case Elements.em:
        return `<em>${children.join("")}</em>`;
      case Elements.subscript:
        return `<sub>${children.join("")}</sub>`;
      case Elements.superscript:
        return `<sup>${children.join("")}</sup>`;
      case Elements.mark:
        return `<mark>${children.join("")}</mark>`;
      case Elements.small:
        return `<small>${children.join("")}</small>`;
      case Elements.deleted:
        return `<del>${children.join("")}</del>`;
      case Elements.inserted:
        return `<ins>${children.join("")}</ins>`;
      case Elements.italics:
        return `<i>${children.join("")}</i>`;
      case Elements.underline:
        return `<u>${children.join("")}</u>`;
      case Elements.listItem:
        return `<li>${children.join("")}</li>`;
      case Elements.oListItem:
        return `<li>${children.join("")}</li>`;
      case Elements.list:
        return `<ul>${children.join("")}</ul>`;
      case Elements.oList:
        return `<ol>${children.join("")}</ol>`;
      case Elements.image:
        var linkUrl = element.linkTo ? PrismicDOM.Link.url(element.linkTo, module.exports.linkResolver) : null;
        let linkTarget = element.linkTo && element.linkTo.target ? `target="${element.linkTo.target}" rel="noopener"` : "";
        let wrapperClassList = [element.label || "", "block-img"];
        let img = `<img src="${element.url}" alt="${element.alt || ""}" copyright="${element.copyright || ""}">`;
        return `
        <p class="${wrapperClassList.join(" ")}">
          ${linkUrl ? `<a ${linkTarget} href="${linkUrl}">${img}</a>` : img}
        </p>
      `;
      case Elements.embed:
        return `
        <div data-oembed="${element.oembed.embed_url}"
          data-oembed-type="${element.oembed.type}"
          data-oembed-provider="${element.oembed.provider_name}"
        >
          ${element.oembed.html}
        </div>
      `;
      case Elements.hyperlink:
        let target = element.data.target ? `target="${element.data.target}" rel="noopener"` : "";
        //  console.log("element.data:", element.data);
        // var linkUrl = PrismicDOM.Link.url(element.data, PrismicConfig.linkResolver);
        var linkUrl = element.data.url;
        return `<a ${target} href="${linkUrl}">${children.join("")}</a>`;
      case Elements.label:
        let label = element.data.label ? ` class="${element.data.label}"` : "";
        return `<span ${label}>${children.join("")}</span>`;
      case Elements.span:
        return content ? content.replace(/\n/g, "<br />") : "";
      default:
        return null;
    }
  }
}
