<div class="nav-margin">
  <span *ngIf="currentBrand === 'Vroom' || currentBrand === 'Schomp' || currentBrand === 'REV'">
    <span *ngIf="isProduction">
      <div *ngIf="(leadData$ | async) as leadData; else guestFlowProd" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-deliveryDate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" [attr.data-merge-language]="preferredLanguage"
        data-slid="countdown-timer-light" data-p="1"></div>
    </span>
    <span *ngIf="!isProduction">
      <div *ngIf="(leadData$ | async) as leadData; else guestFlowDev" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-deliveryDate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" [attr.data-merge-language]="preferredLanguage"
        data-slid="countdown-timer-light-dev" data-p="1"></div>
    </span>
    <ng-template #guestFlowProd>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-light-guestflow-prod" data-p="1">
      </div>
    </ng-template>
    <ng-template #guestFlowDev>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-light-guestflow-dev" data-p="1"></div>
    </ng-template>
  </span>

  <span
    *ngIf="currentBrand === 'GMC' || currentBrand === 'Chevrolet' || currentBrand === 'Buick' || currentBrand === 'Cadillac'">
    <span *ngIf="isProduction">
      <div *ngIf="(leadData$ | async) as leadData; else guestFlowGMProd" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" [attr.data-merge-brand]="currentBrand"
        [attr.data-merge-manufacturingWarrantyEndDate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-gm-prod" data-p="1"></div>
    </span>
    <span *ngIf="!isProduction">
      <div *ngIf="(leadData$ | async) as leadData; else guestFlowGMDev" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
        [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" [attr.data-merge-brand]="currentBrand"
        [attr.data-merge-manufacturingWarrantyEndDate]="leadData.vehicle?.mfrWarrantyDate"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-gm-dev" data-p="1"></div>
    </span>
    <ng-template #guestFlowGMProd>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" [attr.data-merge-brand]="currentBrand" data-slid="countdown-timer-light-guestflow-gm-prod" data-p="1">
      </div>
    </ng-template>
    <ng-template #guestFlowGMDev>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" [attr.data-merge-brand]="currentBrand" data-slid="countdown-timer-light-guestflow-gm-dev" data-p="1">
      </div>
    </ng-template>
  </span>

  <span *ngIf="currentBrand === 'NCESI'">
    <span *ngIf="isProduction">
      <div *ngIf="(vehicleData$ | async)?.mfrWarrantyDate as mfrWarrantyDate; else guestFlowNCESIProd" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-manufacturingWarrantyEndDate]="mfrWarrantyDate" [attr.data-merge-language]="preferredLanguage"
        data-slid="countdown-timer-nissan-prod" data-p="1"></div>
    </span>
    <span *ngIf="!isProduction">
      <div *ngIf="(vehicleData$ | async)?.mfrWarrantyDate as mfrWarrantyDate; else guestFlowNCESIDev" class="tmspslot"
        style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-manufacturingWarrantyEndDate]="mfrWarrantyDate" [attr.data-merge-language]="preferredLanguage"
        data-slid="countdown-timer-nissan-dev" data-p="1"></div>
    </span>
    <ng-template #guestFlowNCESIProd>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-light-guestflow-ncesi-prod" data-p="1">
      </div>
    </ng-template>
    <ng-template #guestFlowNCESIDev>
      <div class="tmspslot" style="width:100%; min-height: 0;" data-k="crz1t0f"
        [attr.data-merge-language]="preferredLanguage" data-slid="countdown-timer-light-guestflow-ncesi-dev" data-p="1">
      </div>
    </ng-template>
  </span>
</div>
