import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Vehicle } from '../_models/vehicle';
import brandInfo from "../../environments/brand.json";
import { environment } from '../../environments/environment';
import { Lead } from '../_models/lead';

@Component({
  selector: 'app-fresh-relevance-header',
  templateUrl: './fresh-relevance-header.component.html',
  styleUrls: ['./fresh-relevance-header.component.scss']
})
export class FreshRelevanceHeaderComponent implements OnInit {

  vehicleData$: Observable<Vehicle>;
  leadData$: Observable<Lead>;
  currentBrand: string;
  production: boolean;
  preferredLanguage: string;

  constructor(
    private store: Store<{
    vehicle: Vehicle;
    lead: Lead;
  }>
  ) { }

  ngOnInit(): void {
    this.vehicleData$ = this.store.select((store) => store.vehicle);
    this.leadData$ = this.store.select((store) => store.lead);
    this.currentBrand = brandInfo.brand;
    this.production = environment.production;
    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
  }
}
