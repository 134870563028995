import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { paypalAuthorizationApproved, rehydratePaypal } from '../_actions/paypal.action';


const _paypalReducer = createReducer(initialState,
    on(paypalAuthorizationApproved, (state, payload) => {
        console.log('paypal authorization', state, payload)
        sessionStorage.setItem('paypal', JSON.stringify(payload))
        return payload;
    }),
    on(rehydratePaypal, (state, payload) => {
        console.log('paypal authorization', state, payload)
        sessionStorage.setItem('paypal', JSON.stringify(payload))
        return payload;
    }),
    
);

export function paypalReducer(state, action) {
    return _paypalReducer(state, action);
}
