import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject, Subscription, Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DataLookupService } from "../_services/data-lookup.service";

import { Store, select } from "@ngrx/store";
import { Lead } from "../_models/lead";
import { Vehicle, VerifyCustVehReq, Vin, VinDecodeData } from "../_models/vehicle";
import { loadVehicle, updateOdometer, updateVehicleByYmmt, updateVehicleByVin, verifyVin, verifyVinReset, verifyVinRates, verifyVinRatesEdit, verifyVehCust } from "../_actions/vehicle.actions";
import { VehicleService } from "../_services/vehicle.service";
import { ModelMakeName } from "../_models/trim";

import { UntypedFormControl, UntypedFormGroup, Validators, Form } from "@angular/forms";
import { Model } from "../_models/model";
import { RatesRequest } from "../_models/rates";
import { hideInlineLoader, showInlineLoader } from "../_actions/inline-loader.actions";
import { guest, account, edit } from "../_actions/app-flow-state.actions";
import { loadRatesHappy } from "../_actions/rates.actions";
import { ratesError, clear, showInlineLoaderModal, hideInlineLoaderModal } from "../_actions/modal-component.actions";
import { environment } from "../../environments/environment";
import brandInfo from "../../environments/brand.json";
import { AppConfigService } from "../appconfig/appconfig.service";
import { LocationService } from "../_services/location.service";
import { PagesService } from "../_services/pages.service";
import { VINDecodeRequest } from "../_models/vin-decode";
import { decodeVinGetRates } from "../_actions/vinDecode.actions";
import { TranslateService } from "@ngx-translate/core";

declare var $: any;
declare var gtag;

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  buttonText = "";
  titleText = "";
  heading: string;
  content: any;
  vehicle: any;
  leadData: string;
  action: Subject<any> = new Subject();
  mileOptions = [];
  states = [];
  leadVehicleOdometer: number;
  editVehicleVin: Vin = {
    vin: "",
  };
  leadState: any;

  private vehicleSubscription: Subscription;
  private leadSubscription: Subscription;
  private vehicleVinSubscription: Subscription;

  private vehicleSub = new Subscription();
  private vinDecodeSub = new Subscription();

  view: any;
  currentBrand: string;

  private lead$: Observable<Lead>;
  vehicle$: Observable<Vehicle>;
  registeredState: Array<any>;
  modelFormValidation: UntypedFormGroup;
  optionsSelect: Array<any>;

  customerVehicle: Vehicle = {
    isElectric: false,
    make: "",
    makeExternalId: "",
    makeSfId: "",
    mileage: 0,
    model: "",
    registeredState: "",
    modelExternalId: "",
    modelSfId: "",
    modelNumber: "",
    purchaseDate: "",
    inserviceDate: "",
    mfrWarrantyDate: "",
    exteriorColor: "",
    deliveryDate: "",
    referenceNumber1: "",
    referenceNumber2: "",
    referenceNumber3: "",
    referenceNumber4: "",
    trim: "",
    trimExternalId: "",
    trimSfId: "",
    vehicleCondition: "",
    vehicleExternalId: "",
    vehicleSfId: "",
    vehicleType: "",
    vin: "",
    year: "",
  };

  makeModel: ModelMakeName = {
    model: "",
    make: "",
  };

  customerVinDecodedData: VinDecodeData = {
    bodyType: "",
    driveLine: "",
    engineCode: "",
    engineType: "",
    fuelType: "",
    make: "",
    manufacturingCountry: "",
    manufacturingPlant: "",
    model: "",
    series: "",
    trimLevel: "",
    vehicleClass: "",
    vehicleSubtype: "",
    vehicleType: "",
    vehicleYear: "",
    vin: "",
  };

  editVehicleYear: any;
  editVehicleMake: any;
  editVehicleModel: any;
  editVehicleTrim: any;
  editVehicleRegisteredStateVIN: any;
  editVehicleRegisteredStateYMMT: any;
  editVehicleOdometerVIN: any;
  editVehicleOdometerYMMT: any;
  vehicleVin: any;

  modelKeyword = "name";
  modelData = [];
  modelFormat = {
    id: 0,
    name: "",
  };

  trimKeyword = "name";
  trimData = [];
  trimFormat = {
    id: 0,
    name: "",
  };

  stateKeyword = "name";
  stateData = [];
  stateFormat = {
    id: 0,
    name: "",
  };
  trimSubscription: Subscription;
  vinDecodeSubscription: Subscription;
  modelsSubscription: Subscription;
  trimBoolean: boolean;
  modelsBoolean: boolean;
  vinDecodeBoolean: boolean;
  modalComponentState$: Observable<any>;
  appFlowState$: Observable<any>;
  inlineLoader$: Observable<any>;
  editVehicleOdometer: any;
  editVehicleVINForm: UntypedFormGroup;
  editVehicleYMMTForm: UntypedFormGroup;
  editVehicleState$: Observable<any>;
  vehicleBoolean: boolean;
  environment: boolean;
  vehicleRegisteredState: any;
  stateFromCustomize: any;
  verifyVin$: Observable<any>;
  ratesStateSubscription: Subscription;
  branding: any;
  inlineLoaderModal$: Observable<any>;
  odometerTooltip;

  constructor(
    private translate: TranslateService,
    public modalRef: MDBModalRef,
    private router: Router,
    private modelTrim: DataLookupService,
    private pageService: PagesService,
    private store: Store<{
      lead: Lead;
      vehicle: Vehicle;
      modalComponentState: any;
      appFlowState: any;
      inlineLoader: any;
      editVehicleState: any;
      verifyVin: any;
      vinDecode: any;
      inlineLoaderModal: any;
    }>,
    private locationService: LocationService,
    private appConfig: AppConfigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.store.dispatch(showInlineLoaderModal());

    this.appConfig.getSettings().subscribe((result) => {
      this.odometerTooltip = this.appConfig.getOdometerTooltip();
      this.branding = result;
    });

    this.currentBrand = brandInfo.brand;
    console.log('currentBrand: ', this.currentBrand)

    this.store.dispatch(clear());
    this.modalComponentState$ = this.store.select((store) => store.modalComponentState);
    this.appFlowState$ = this.store.select((store) => store.appFlowState);
    this.inlineLoader$ = this.store.select((store) => store.inlineLoader);
    this.editVehicleState$ = this.store.select((store) => store.editVehicleState);
    this.verifyVin$ = this.store.select((store) => store.verifyVin);
    this.inlineLoaderModal$ = this.store.select((store) => store.inlineLoaderModal);
    this.environment = environment.production;

    this.editVehicleVINForm = new UntypedFormGroup({
      editVehicleVIN: new UntypedFormControl(null, Validators.required),
      editVehicleOdometerVIN: new UntypedFormControl(null, Validators.required),
      editVehicleRegisteredStateVIN: new UntypedFormControl(null, Validators.required),
    });

    this.editVehicleYMMTForm = new UntypedFormGroup({
      editVehicleYear: new UntypedFormControl(null, Validators.required),
      editVehicleModel: new UntypedFormControl(null, Validators.required),
      editVehicleTrim: new UntypedFormControl(null, Validators.required),
      editVehicleOdometerYMMT: new UntypedFormControl(null, Validators.required),
      editVehicleRegisteredStateYMMT: new UntypedFormControl(null, Validators.required),
    });

    this.modelFormValidation = new UntypedFormGroup({
      registeredState: new UntypedFormControl(null, Validators.required),
      odometer: new UntypedFormControl(null, Validators.required),
    });

    this.lead$ = this.store.select((store) => store.lead);
    this.vehicle$ = this.store.select((store) => store.vehicle);

    this.vehicleSubscription = this.vehicle$.subscribe((vehicle) => {
      if (vehicle) {
        this.maskVin(vehicle.vin.toUpperCase());
        this.store.dispatch(hideInlineLoaderModal());
      }
    });

    this.ratesStateSubscription = this.store
      .select((store) => store.modalComponentState)
      .subscribe((ratesState) => {
        if (ratesState === "success") {
          this.modalRef.hide();
          let ratingPage = this.pageService.getRatingPage();
          this.router.navigate([ratingPage]);
        }
      });

    this.mileOptions = this.getMileOptions();

    this.stateData = this.locationService.getStateData();

    let _lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

    if (_vehicle) {
      this.maskVin(_vehicle.vin.toUpperCase());
      console.log('State From Customize', this.stateFromCustomize)
      if (_vehicle.registeredState) {
        this.modelFormValidation.controls["odometer"].markAsDirty();
        this.modelFormValidation.controls["registeredState"].markAsDirty();
        this.modelFormValidation.controls["odometer"].markAsTouched();
        this.modelFormValidation.controls["registeredState"].markAsTouched();

        this.modelFormValidation.controls["odometer"].setValue(_vehicle.mileage);
        this.modelFormValidation.controls["registeredState"].setValue(_vehicle.registeredState);
      } else if (_lead) {

        this.leadState = _lead.customer.state;
        for (let i = 0; i < this.stateData.length; i++) {
          if (this.leadState === this.stateData[i].value) {
            this.modelFormValidation.controls["registeredState"].markAsDirty();
            this.modelFormValidation.controls["registeredState"].markAsTouched();

            this.modelFormValidation.controls["registeredState"].setValue(this.stateData[i].value);
            this.vehicleRegisteredState = this.leadState;
          }
        }
      }
    }


    this.translate.get('ASP_LANDING').subscribe(asp => {
      this.buttonText = asp.VERIFY_VEHICLE_BUTTON
      this.titleText = asp.VERIFY_VEHICLE

      if (brandInfo.brand === 'Buick') {
        this.buttonText = "Find My Price";
        this.titleText = "Your Buick, Your Plans";
      } else if (brandInfo.brand === 'Chevrolet') {
        this.buttonText = "Find My Price";
        this.titleText = "Keep Your Chevrolet Covered";
      } else if (brandInfo.brand === 'GMC') {
        this.buttonText = "Find My Price";
        this.titleText = "Put the Pro in Protection";
      } else if (brandInfo.brand === 'Cadillac') {
        this.buttonText = "Find My Price";
        this.titleText = "Boldly Built.<br>Confidently Covered.";
      }
    })

    setTimeout(() => {
      if (document.getElementById("verifyYourVehicle")) {
        document.getElementById("verifyYourVehicle").focus();
      }
      if (document.getElementById("verifyYourVehicle2")) {
        document.getElementById("verifyYourVehicle2").focus();
      }
    }, 100);
  }

  fixSelectAria(event) {
    setTimeout(() => {
      document.querySelectorAll(".dropdown-content li").forEach((li, index) => {
        var span = li.querySelector("span");
        if (span) {
          span.setAttribute("tabindex", "0");
        }
        if (li.className.indexOf("custom-select-content") > -1) {
          li.setAttribute("aria-hidden", "true"); // fix screen reader count of items
        }
        if (index === 0) {
          li.querySelector("span").focus();
        }
      });
    }, 100);
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.modalRef.hide();
    console.log("ngOnDestroy");
    if (this.trimBoolean) {
      this.trimSubscription.unsubscribe();
    }
    if (this.modelsBoolean) {
      this.modelsSubscription.unsubscribe();
    }
    if (this.vinDecodeBoolean) {
      this.vinDecodeSubscription.unsubscribe();
    }
    if (this.vehicleSubscription) {
      this.vehicleSubscription.unsubscribe();
    }
    if (this.ratesStateSubscription) {
      this.ratesStateSubscription.unsubscribe();
    }
  }

  onYesClick() {
    this.action.next("yes");
  }

  vehicleNotEdited() {
    this.store.dispatch(clear());
    this.store.dispatch(showInlineLoader());
    this.loadNotEditedVehicle();
  }

  vehicleEditedVIN() {
    this.customerVehicle.mileage = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value;
    this.customerVehicle.registeredState = this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value;
    this.customerVehicle.vin = this.editVehicleVINForm.controls["editVehicleVIN"].value;
    let ratingPage = this.pageService.getRatingPage();

    // console.log("vehicleEditedVIN vehicleEdited:", this.store);
    this.saveRates(this.customerVehicle.vin,  this.customerVehicle.registeredState, this.customerVehicle.mileage, ratingPage);
  }

  vehicleEditedYMMT() {
    this.customerVehicle.make = this.editVehicleMake;
    this.customerVehicle.model = this.editVehicleYMMTForm.controls["editVehicleModel"].value;
    this.customerVehicle.trim = this.editVehicleYMMTForm.controls["editVehicleTrim"].value;
    this.customerVehicle.mileage = this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value;
    this.customerVehicle.registeredState = this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value;
    this.customerVehicle.year = this.editVehicleYMMTForm.controls["editVehicleYear"].value;

    this.store.dispatch(updateVehicleByYmmt(this.customerVehicle));

    console.log("vehicleEditedYMMT vehicleEdited:", this.store);
    this.modalRef.hide();
  }

  removeCommas() {
    if (this.modelFormValidation.controls["odometer"].value && this.modelFormValidation.controls["odometer"].value.includes(',')) {
      this.modelFormValidation.controls["odometer"].setValue(this.modelFormValidation.controls["odometer"].value.replace(",", ""));
    }
  }

  addCommas() {
    if (this.modelFormValidation.controls["odometer"].value) {
      if (this.modelFormValidation.controls["odometer"].value.length === 4) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 1);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(1, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length === 5) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 2);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(2, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length === 6) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 3);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(3, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length > 6) {
        this.modelFormValidation.controls["odometer"].setValue("999,999");
      }
    }
  }

  removeCommasVIN() {
    if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.replace(",", ""));
    }
  }

  addCommasVIN() {
    if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 4) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 1);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(1, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 5) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 2);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(2, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 6) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 3);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(3, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length > 6) {
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue("999,999");
      }
    }
  }

  loadNotEditedVehicle() {
    // gtag('event', 'Customer confirmed car and went to pricing', {
    //   'event_label': 'User Interaction',
    //   'event_category': 'Meet My Car Page'
    // })

    console.log(this.modelFormValidation.controls["odometer"].value);

    this.customerVehicle.mileage = this.modelFormValidation.controls["odometer"].value;
    this.customerVehicle.registeredState = this.modelFormValidation.controls["registeredState"].value;
    console.log("loadNotEditedVehicle", this.store);

    let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

    let d = new Date();
    let year = d.getFullYear().toString();
    let day = d.getDate().toString();
    let month = d.getMonth().toString();

    let isd = _accountContact.vehicle.inserviceDate
    console.log('Ray find', isd.split('-'))

    let isd_year = isd.split('-')[2]
    let isd_day = isd.split('-')[1]
    let isd_month = isd.split('-')[0]

    let ratingPage = this.pageService.getRatingPage();

    let request: RatesRequest = {
      brandName: brandInfo.brand,
      vin: _accountContact.vehicle.vin,
      saleDate: year + "-" + month + "-" + day,
      odometer: this.modelFormValidation.controls["odometer"].value,
      inServiceDate: isd_year + "-" + isd_month + "-" + isd_day,
      financeType: "finance",
      isAfterSale: "false",
      vehiclePurchaseDate: year + "-" + month + "-" + day,
      vehicleCondition: _accountContact.vehicle.vehicleCondition,
      customerState: this.modelFormValidation.controls["registeredState"].value,
      financeAmount: "",
      vehicleCost: "",
      vehicleMSRP: "",
      lienholderName: "",
      make: _accountContact.vehicle.make,
      model: _accountContact.vehicle.model,
      year: _accountContact.vehicle.year,
      vehicleSfId: _accountContact.vehicle.vehicleSfId,
      getRatesPage: ratingPage,
      product: ''
    };

    console.log(request);

    this.store.dispatch(verifyVinRates(request));
  }

  checkStringLength(event) {
    if (this.editVehicleVINForm.controls["editVehicleVIN"].value.length > 0) {
      let vin: string = this.editVehicleVINForm.controls["editVehicleVIN"].value.toUpperCase();
      this.editVehicleVINForm.controls["editVehicleVIN"].setValue(vin);
      let length: number = vin.length;
      let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
      console.log(length);
      if (length === 17) {
        let request: VerifyCustVehReq = {
          vin: vin,
          email: _accountContact?.customer.emailId,
        };
        this.store.dispatch(verifyVehCust(request));
      }
    }

  }

  clearYMMT() {
    this.editVehicleYMMTForm.reset();
  }

  clearVIN() {
    this.editVehicleVINForm.reset();
  }

  runRatesForYMMT() {
    if (
      this.editVehicleYMMTForm.controls["editVehicleModel"].value &&
      this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value &&
      this.editVehicleYMMTForm.controls["editVehicleYear"].value &&
      this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value &&
      this.editVehicleYMMTForm.controls["editVehicleTrim"].value
    ) {
      this.store.dispatch(showInlineLoader());

      let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
      let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));

      console.log(_accountContact);

      let ratingPage = this.pageService.getRatingPage();

      let request: RatesRequest = {
        brandName: brandInfo.brand,
        vin: "5NPE34AB2JH691640",
        saleDate: "2019-10-11",
        odometer: this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value,
        inServiceDate: "2019-05-13",
        financeType: "finance",
        isAfterSale: "false",
        vehiclePurchaseDate: "2019-10-11",
        vehicleCondition: "NEW",
        customerState: this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value,
        financeAmount: "0.0",
        vehicleCost: "0.0",
        vehicleMSRP: "0.0",
        lienholderName: "",
        make: this.editVehicleMake,
        model: this.editVehicleYMMTForm.controls["editVehicleModel"].value,
        year: this.editVehicleYMMTForm.controls["editVehicleYear"].value,
        vehicleSfId: "",
        getRatesPage: ratingPage,
        product: ''
      };
      this.store.dispatch({ type: "[Product Plans Component] Load Rates", payload: request });
      this.clearVIN();
    }
  }

  getYMMT() {
    this.vinDecodeSubscription = this.store
      .select((store) => store.vinDecode)
      .subscribe((vinDecodeData) => {
        this.vinDecodeBoolean = true;
        this.customerVinDecodedData = vinDecodeData;
        this.editVehicleMake = this.customerVinDecodedData.make;
        this.editVehicleModel = this.customerVinDecodedData.model;
        this.editVehicleTrim = this.customerVinDecodedData.trimLevel;
        this.editVehicleYear = this.customerVinDecodedData.vehicleYear;
      });
  }

  async _loadTrims(trims) {
    for (let i = 0; i < trims.trims.length; i++) {
      this.trimFormat.id = i + 1;
      this.trimFormat.name = trims.trims[i].name;
      if (this.trimData.length < trims.trims.length) {
        this.trimData.push({ id: this.trimFormat.id, name: trims.trims[i].name });
      }
    }
    console.log(this.trimData);
  }

  odometerSelectedVIN(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometerVIN = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometerVIN = y[0] + y[1];

      console.log(this.editVehicleVINForm.controls["editVehicleVIN"].value, this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value);

      // if (this.editVehicleVINForm.controls['editVehicleVIN'].value && this.editVehicleVINForm.controls['editVehicleRegisteredStateVIN'].value && this.editVehicleVINForm.controls['editVehicleOdometerVIN'].value) {
      //   this.clearYMMT()
      //   this.saveRates();
      // }
    }
  }

  odometerSelected(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometer = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometer = y[0] + y[1];
    }

    if (this.modelFormValidation.controls["registeredState"].value && this.modelFormValidation.controls["odometer"].value) {
      this.vehicleNotEdited();
    }
  }

  odometerSelectedYMMT(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometerYMMT = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometerYMMT = y[0] + y[1];

      this.runRatesForYMMT();
    }
  }

  stateSelected() {
    this.vehicleRegisteredState = this.modelFormValidation.controls["registeredState"].value;
  }

  stateSelectedVIN(event) {
    console.log(event);
    this.editVehicleRegisteredStateVIN = event.value;

    console.log(this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value);

    if (this.editVehicleVINForm.controls["editVehicleVIN"].value && this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value && this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      this.clearYMMT();
    }
  }

  stateSelectedYMMT(event) {
    console.log(event);
    this.editVehicleRegisteredStateYMMT = event.value;
  }

  async loadModelsFromMakes(models: Model[]) {
    for (let i = 0; i < models.length; i++) {
      this.modelFormat.id = i + 1;
      this.modelFormat.name = models[i].name;
      if (this.modelData.length < models.length) {
        this.modelData.push({ id: this.modelFormat.id, name: models[i].name });
      }
    }
    console.log(this.modelData);
  }

  onNoClick() {
    this.action.next("No");
  }

  refreshState() {
    this.modalRef.hide();
    let state = sessionStorage.getItem("appFlowState");
    if (state === "account" || state === "edit") {
      this.store.dispatch(account());
    } else {
      this.store.dispatch(guest());
      console.log('Ray here 8')
    }
    this.store.dispatch(clear());
    this.store.dispatch(verifyVinReset());
  }

  editVehicle() {
    this.store.dispatch(clear());
    this.store.dispatch(edit());
  }

  saveRates(vin, state, odometer, getRatesPage) {
    this.store.dispatch(showInlineLoader());

    // let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    // let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    // let currentCompatibleDate = this.convertToCompatibleDate();

    // let d = _accountContact?.vehicle.inserviceDate || currentCompatibleDate;
    // console.log('Ray find', d.split('-'))

    // let year = d.split('-')[2]
    // let day = d.split('-')[1]
    // let month = d.split('-')[0]

    let request: VINDecodeRequest = {
      brandName: brandInfo.brand,
      vin: vin,
      saleDate: '',
      odometer: odometer,
      inServiceDate: '',
      customerState: state,
      getRatesPage: getRatesPage,
    };

    this.store.dispatch(decodeVinGetRates(request))

  }

  // saveRates() {
  //   this.store.dispatch(showInlineLoader());

  //   let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
  //   let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));

  //   let d = new Date();
  //   let year = d.getFullYear().toString();
  //   let day = d.getDate().toString();
  //   let month = d.getMonth().toString();
//
  //   let isd = _accountContact?.vehicle.inserviceDate || this.convertToCompatibleDate();
  //   console.log('Ray find', isd.split('-'))

  //   let isd_year = isd.split('-')[2]
  //   let isd_day = isd.split('-')[1]
  //   let isd_month = isd.split('-')[0]

  //   console.log(year + "-" + month + "-" + day);

  //   let ratingPage = this.pageService.getRatingPage();

  //   let request: RatesRequest = {
  //     brandName: brandInfo.brand,
  //     vin: this.editVehicleVINForm.controls["editVehicleVIN"].value,
  //     saleDate: year + "-" + month + "-" + day,
  //     odometer: this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value,
  //     inServiceDate: isd_year + "-" + isd_month + "-" + isd_day,
  //     financeType: "finance",
  //     isAfterSale: "false",
  //     vehiclePurchaseDate: year + "-" + month + "-" + day,
  //     vehicleCondition: "NEW",
  //     customerState: this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value,
  //     financeAmount: "",
  //     vehicleCost: "",
  //     vehicleMSRP: "",
  //     lienholderName: "",
  //     make: this.editVehicleMake,
  //     model: this.editVehicleModel,
  //     year: this.editVehicleYear,
  //     vehicleSfId: "",
  //     getRatesPage: ratingPage,
  //   };
  //   this.store.dispatch(verifyVinRatesEdit(request));
  //   // this.editVehicleMake = null;
  //   // this.editVehicleModel = null;
  //   // this.editVehicleYear = null;
  //   // this.editVehicleVin.vin = null;
  //   // this.editVehicleTrim = null;
  // }

  maskVin(vin) {
    console.log(vin);
    let endVin = vin.substring(11, 17);
    console.log(endVin);
    this.vehicleVin = "***********" + endVin;
  }

  moveToRates() {
    let ratingPage = this.pageService.getRatingPage();
    this.router.navigate([ratingPage]);
  }

  getMileOptions() {
    return [
      { value: "30000", label: "0 - 30,000" },
      { value: "60000", label: "30,001 - 60,000" },
      { value: "90000", label: "60,001 - 90,000" },
    ];
  }
  onlyNumberKey(event) {
    return event.charCode == 8 || event.charCode == 0 ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  convertToCompatibleDate() {
    let d = new Date();
    let year = d.getFullYear().toString();
    let day = d.getDate().toString();
    let month = d.getMonth().toString();

    if (month === "0") {
      month = "01";
    } else if (month === "1") {
      month = "02";
    } else if (month === "2") {
      month = "03";
    } else if (month === "3") {
      month = "04";
    } else if (month === "4") {
      month = "05";
    } else if (month === "5") {
      month = "06";
    } else if (month === "6") {
      month = "07";
    } else if (month === "7") {
      month = "08";
    } else if (month === "8") {
      month = "09";
    } else if (month === "9") {
      month = "10";
    } else if (month === "10") {
      month = "11";
    } else if (month === "11") {
      month = "12";
    }

    if (day === "1") {
      day = "01";
    } else if (day === "2") {
      day = "02";
    } else if (day === "3") {
      day = "03";
    } else if (day === "4") {
      day = "04";
    } else if (day === "5") {
      day = "05";
    } else if (day === "6") {
      day = "06";
    } else if (day === "7") {
      day = "07";
    } else if (day === "8") {
      day = "08";
    } else if (day === "9") {
      day = "09";
    }

    return year + "-" + month + "-" + day;
  }

}
