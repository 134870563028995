import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { PaymentService } from '../_services/payment-and-tax-service.service';
import { Action, Store } from '@ngrx/store';
import { avsSubmitted } from '../_actions/avs.actions';
import { hideInlineLoader } from '../_actions/inline-loader.actions'
import { avsFailed, avsSuccess, avsReset } from '../_actions/avs-state.actions';
import { Injectable } from '@angular/core';
import { request } from 'http';
import { Opportunity } from '../_models/account';
import { AVSTokenRefresh } from '../_models/token-refresh';
import brandInfo from "src/environments/brand.json";
import { removeToken } from '../_actions/token.actions';
import { checkoutAVSTokenRefresh } from '../_actions/token-refresh.actions';
import { ProspectService } from '../_services/prospect-service.service';
import { createCaseDirectMarketingFailed, createCaseDirectMarketingSuccess, storeUserDirectMarketingFailed, storeUserDirectMarketingSuccess } from '../_actions/prospect.actions';

@Injectable()

export class ProspectEffects {

    public createCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.CREATE_A_CASE_DIRECT_MARKETING),
            switchMap((action: any) =>
                this.prospectService.createCase(action).pipe(
                    map((response: any) => {
                        return createCaseDirectMarketingSuccess()
                    }),
                    catchError(() => {
                        this.store.dispatch(createCaseDirectMarketingFailed())
                        return EMPTY
                    })
                )
            )
        )
    );

    public storeUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.STORE_USER_DIRECT_MARKETING),
            switchMap((action: any) =>
                this.prospectService.storeUser(action).pipe(
                    map((response: any) => {
                        return storeUserDirectMarketingSuccess()
                    }),
                    catchError(() => {
                        this.store.dispatch(storeUserDirectMarketingFailed())
                        return EMPTY
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private prospectService: ProspectService,
        private store: Store<{}>
    ) { }
}
