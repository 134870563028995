import * as ActionConfigurationManager from './config.actions';


export const paypalAuthorizationApproved = ActionConfigurationManager.AUTHORIZED_PAYPAL_APPROVED;
export const paypalChangePaymentOptionClick = ActionConfigurationManager.CHANGE_PAYMENT_OPTION_CLICK;
export const cancelAuthorizedPayment = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT;

export const cancelAuthorizedPaymentSuccess = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT_SUCCESS;
export const cancelAuthorizedPaymentFailed = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT_FAILED;

export const rehydratePaypal = ActionConfigurationManager.REHYDRATE_PAYPAL;
