import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, ModalOptions } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import brandInfo from '../../environments/brand.json'

@Component({
  selector: 'app-modal-timeout',
  templateUrl: './modal-timeout.component.html',
  styleUrls: ['./modal-timeout.component.scss']
})
export class ModalTimeoutComponent implements OnInit {
  public modalType: string;
  public action: Subject<any> = new Subject();

  constructor(public modalRef: MDBModalRef, private router: Router, 
    public options: ModalOptions
    ) { }

  ngOnInit(): void {
    console.log(this.options.data); // here you will get the value
   }

  killSession(){
    this.router.navigate([`/${brandInfo.homePage}`]);
    this.action.next('killsession');
  }

  keepSession(){
    this.action.next('keepalive');
  }

  renew() {
    location.reload();
  }
}
