import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import {
  Account,
  Paylink,
  AccountResponse,
  MyAccount,
  AccountID,
} from "../_models/account";
import {
  VerifyAccountResponse,
  VerifyCodeResponse,
} from "../_models/verify-account";
import { environment } from "../../environments/environment";
import brandInfo from '../../environments/brand.json'
import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";

declare var gtag;

@Injectable({
  providedIn: "root",
})
export class AccountService {

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private loggingService: LoggingService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  get_account(object): Observable<AccountResponse> {
    console.log(object.payload);
    return this.httpClient
      .get<AccountResponse>(
        this.gatewayService.baseUrl +
          "dtc-account-srvc/account/" +
          object.payload.accountID
      )
      .pipe(retry(1), catchError(this.loggingService.handleError));
  }

  get_account_id(object): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/account/getaccountsfid`, {
      params: {
        email: encodeURIComponent(object.email.toLowerCase())
      }
    })
    .pipe(
      retry(1)
    )
  }

  get_paylink(object: AccountID): Observable<any> {
    console.log(object);
    return this.httpClient.get<any>(
      this.gatewayService.baseUrl + "dtc-payment-srvc/customerData",
      {
        params: {
          accountNumber: object.accountID,
        },
      }
    );
  }

  verify_account(object): Observable<VerifyAccountResponse> {
    console.log(object);
    let contact = "";
    if (object.emailId === null) {
      contact = object.phoneNumber;
    } else {
      contact = object.emailId;
    }
    return this.httpClient.get<VerifyAccountResponse>(
      this.gatewayService.baseUrl + "dtc-account-srvc/account/verify",
      {
        params: {
          contact: encodeURIComponent(contact),
          contactType: object.contactType,
          lastName: object.lastName,
          brandName: object.brandName
        },
      }
    );
  }

  verify_code(object): Observable<VerifyCodeResponse> {
    console.log(object);
    return this.httpClient.get<VerifyCodeResponse>(
      this.gatewayService.baseUrl + "dtc-account-srvc/account/verifycode",
      {
        params: {
          contact: encodeURIComponent(object.contact),
          contactType: object.contactType,
          code: object.code,
          lastName: object.lastName,
          brandName: brandInfo.brand
        },
      }
    );
  }

  logout() {
    // Clear Session Storage



    // Clear ngrx store
  }


}
